/**
 * 
 */
 const BannerType = Object.freeze({
    VIDEO: {
        key:"VIDEO",
        description: "视频"
    },
    PICTURE: {
        key:"PICTURE",
        description: "图片"
    },
})

export default BannerType