/**
 * 
 */
const NotificationType = Object.freeze({
    DOCUMENT: {
        key: "DOCUMENT",
        description: "文本通告"
    },
    LINK: {
        key: "LINK",
        description: "链接通告"
    },
    VIDEO: {
        key: "VIDEO",
        description: "视频通告"
    },
})

export default NotificationType