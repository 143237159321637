Date.prototype.format = function(fmt) {
  var o = {
    "M+": this.getMonth() + 1, //月份
    "d+": this.getDate(), //日
    "h+": this.getHours(), //小时
    "m+": this.getMinutes(), //分
    "s+": this.getSeconds(), //秒
    "q+": Math.floor((this.getMonth() + 3) / 3), //季度
    S: this.getMilliseconds(), //毫秒
  };
  if (/(y+)/.test(fmt)) {
    fmt = fmt.replace(
      RegExp.$1,
      (this.getFullYear() + "").substr(4 - RegExp.$1.length)
    );
  }
  for (var k in o) {
    if (new RegExp("(" + k + ")").test(fmt)) {
      fmt = fmt.replace(
        RegExp.$1,
        RegExp.$1.length == 1 ? o[k] : ("00" + o[k]).substr(("" + o[k]).length)
      );
    }
  }
  return fmt;
};

Date.prototype.timeFix = function () {
  const time = this
  const hour = time.getHours()
  return hour < 9 ? '早上' : hour <= 11 ? '上午' : hour <= 13 ? '中午' : hour < 20 ? '下午' : '晚上'
}

// 获取当天时间戳
Date.prototype.nowDate = function(fmt) {
  let M = this.getMonth() + 1, //月份
    Y = this.getFullYear(),
    D = this.getDate();
  fmt = `${Y}-${M}-${D} 00:00:00`;
  fmt = new Date(fmt).getTime();
  return fmt;
};

// 获取天制时间戳
Date.prototype.dateTime = function(days, fmt) {
  fmt = 60 * 60 * 1000 * 24 * days;
  return fmt;
};
