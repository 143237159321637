/**
 * 
 */
 const ComponentType = Object.freeze({
    DESKTOP_COMPONENT: {
        key:"DESKTOP_COMPONENT",
        description: "桌面组件"
    },
    MOBILE_COMPONENT: {
        key:"MOBILE_COMPONENT",
        description: "移动端组件"
    }
})

export default ComponentType