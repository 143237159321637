/**
 * 应用的类型
 */
 const ApplicationCategory = Object.freeze({
    PRE_REGISTRATION: {
        key:"PRE_REGISTRATION",
        description: "预登记应用"
    },
    ON_SITE_REGISTRATION: {
        key:"ON_SITE_REGISTRATION",
        description: "现场登记应用"
    },
    NAVIGATION: {
        key:"NAVIGATION",
        description: "导览应用"
    }
})

export default ApplicationCategory