const AdvertisementType = Object.freeze({
    PICTURE: {
        key: "PICTURE",
        description: "图片广告"
    },
    EXHIBIT: {
        key: "EXHIBIT",
        description: "展品广告"
    },
    EXHIBITOR: {
        key: "EXHIBITOR",
        description: "展商广告"
    }
})

export default AdvertisementType