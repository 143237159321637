/**
 *展馆类型枚举，方便后期维护和适配
 */
 const PavilionType = Object.freeze({
    VIRTUAL: {
        key:"VIRTUAL",
        description: "虚拟展馆"
    },
    STUFF: {
        key:"STUFF",
        description: "实物展馆"
    },
})

export default PavilionType