<template>
  <div>
    <a-checkable-tag
      v-if="checkable"
      v-model="checked"
      :closable="closable"
      :color="tagColor"
      @change="handleChange"
    >
      {{ content }}
    </a-checkable-tag>
    <a-tag
      v-else
      v-model="visible"
      :closable="closable"
      :color="tagColor"
      @close="handleChange"
      @click="handleClick"
    >
      {{ content }}
    </a-tag>
  </div>
</template>

<script>
import Enums from "../../enums";

const colors = ["pink", "red", "orange", "green", "cyan", "blue", "purple"];

export default {
  model: {
    prop: "value",
    event: "change",
  },
  props: {
    checkable: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: "",
    },
    value: {
      type: String,
      default: "",
    },
    closable: {
      type: Boolean,
      default: false,
    },
    color: String,
    visible: {
      type: Boolean,
      default: true,
    },
    checked: {
      type: Boolean,
      default: false,
    },
    to: {
      type: String,
      default: "",
    },
  },
  computed: {
    content: function () {
      // 未传递类型则为普通标签
      if (this.type == "") {
        return this.value;
      }

      const targetEnum = Enums.getEnumValueByKey(this.type, this.value);

      if (!targetEnum) {
        return "";
      }

      return targetEnum.description;
    },
  },
  data() {
    return {
      tagColor: "",
    };
  },
  methods: {
    handleChange(value) {
      if (this.checkable) {
        this.$emit("update:checked", value);
      } else {
        this.$emit("update:visible", value);
      }
    },
    handleClick() {
      if (this.to) {
        this.$router.push(this.to);
      }
    },
  },
  created() {
    // 未传递类型则无颜色
    if (this.type == "") {
      this.tagColor = "";
    }

    for (const enumKey in Enums) {
      if (enumKey == this.type) {
        for (const key in Enums[enumKey]) {
          if (key == this.value) {
            if (this.color) {
              this.tagColor = this.color;
            } else {
              this.tagColor =
                colors[Object.keys(Enums[enumKey]).findIndex((k) => k == key)];
            }
          }
        }
      }
    }
  },
};
</script>

<style>
</style>