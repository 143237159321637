<template>
  <a-radio-group
    v-model="actualValue"
    :defaultValue="defaultValue"
    :disabled="disabled"
    :name="name"
    :size="size"
    @change="handleChange"
  >
    <template v-for="option in options">
      <!-- Radio Button -->
      <a-radio-button
        v-if="enableRadioButton"
        :value="option.key"
        :key="option.key"
      >
        {{ option.name }}
      </a-radio-button>
      <!-- Radio -->
      <a-radio v-else :value="option.key" :key="option.key">
        {{ option.name }}
      </a-radio>
    </template>
  </a-radio-group>
</template>

<script>
import Enums from "@/enums";

export default {
  model: {
    prop: "value",
    event: "change",
  },
  props: {
    type: {
      type: String,
      default: "",
    },
    dataSource: {
      type: Array,
      default: function () {
        return [];
      },
    },
    defaultValue: Object,
    disabled: Boolean,
    name: String,
    size: String,
    value: String,
    enableRadioButton: {
      type: Boolean,
      default: true,
    },
    buttonStyle: {
      type: String,
      default: "solid",
    },
    excludedOptions: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    options: function () {
      // 未传递类型则为普通标签
      if (!this.type || this.type == "") {
        return this.dataSource.map((data) => {
          return { key: data.id, name: data.name };
        });
      }

      if (this.type == "custom") {
        return this.dataSource;
      }

      const options = [];

      const targets = Enums.getEnumByName(this.type);
      if (!targets) {
        return options;
      }

      for (const targetKey in targets) {
        if (this.excludedOptions.indexOf(targetKey) == -1) {
          options.push({
            key: targetKey,
            name: targets[targetKey].description,
          });
        }
      }

      return options;
    },
    actualValue: {
      get: function () {
        return this.value;
      },
      set: function (value) {
        this.$emit("change", value);
      },
    },
  },
  methods: {
    handleChange(event) {
      event.stopPropagation();
      this.$emit("change", event.target.value);
    },
  },
};
</script>

<style></style>
