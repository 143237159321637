/**
 * 
 */
const AccountType = Object.freeze({
    USER_NAME: {
        key: "USER_NAME",
        description: "用户名密码"
    },
    WECHAT_APPLET: {
        key: "WECHAT_APPLET",
        description: "微信小程序"
    },
    PHONE: {
        key: "PHONE",
        description: "手机号"
    },
    EMAIL: {
        key: "EMAIL",
        description: "邮箱"
    }
})

export default AccountType