/**
 * 用户的性别
 */
const Gender = Object.freeze({
    MALE: {
        key:"MALE",
        description: "男"
    },
    FEMALE: {
        key:"FEMALE",
        description: "女"
    },
    UNKNOWN: {
        key:"UNKNOWN",
        description: "未知"
    }
})

export default Gender