import request from "../libs/request"
import EntityService from "./entity";

class AuthenticationService extends EntityService {

	constructor(name) {
		super(name)
	}

	async addAuthenticationPhoneVerificationCode(entity) {
		const result = await request({
			url: `/${this.name}/phone-verification-code`,
			method: "post",
			data: entity,
		});

		return result;
	}

}

export default new AuthenticationService("authentication")
