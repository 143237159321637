<template>
  <a-row class="authentication" align="middle" justify="center" type="flex">
    <a-col span="15" class="picture">
      <img src="../../assets/authentication.svg" alt="" />
    </a-col>
    <a-col span="9" class="form">
      <router-view></router-view>
    </a-col>
  </a-row>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
.authentication {
  height: 100%;

  .picture {
    background-color: #fcfcfc;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 66%;
      height: auto;
    }
  }

  .form {
    padding: 32px;
  }
}
</style>
