/**
 * 应用的平台类别
 */
 const ApplicationType = Object.freeze({
    DESKTOP_WEB: {
        key:"DESKTOP_WEB",
        description: "桌面网页"
    },
    MOBILE_WEB: {
        key:"MOBILE_WEB",
        description: "移动端网页"
    },
    WECHAT_APPLET:{
        key:"WECHAT_APPLET",
        description:"微信小程序"
    }
})

export default ApplicationType