/**
 * 文档的类型
 */
const DocumentType = Object.freeze({
    PAGE: {
        key:"PAGE",
        description: "页面"
    },
    ARTICLE: {
        key:"ARTICLE",
        description: "新闻"
    }
})

export default DocumentType