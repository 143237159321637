/**
 * 
 */
 const CommodityType = Object.freeze({
    PHYSICAL: {
        key:"PHYSICAL",
        description: "实物实物"
    },
    CERTIFICATE: {
        key:"CERTIFICATE",
        description: "兑换券"
    },
    TICKET: {
        key:"TICKET",
        description: "票"
    }
})

export default CommodityType