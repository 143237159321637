/**
 * 展览商品的类型
 */
 const ExhibitType = Object.freeze({
    STUFF: {
        key:"STUFF",
        description: "实体展品"
    },
    SOLUTIONS: {
        key:"SOLUTIONS",
        description: "虚拟展品"
    },
})

export default ExhibitType