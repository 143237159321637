<template>
  <a-select
    class="select"
    v-model="current"
    :allowClear="allowClear"
    :defaultValue="defaultValue"
    :disabled="disabled"
    :filterOption="handleFilter"
    :placeholder="placeholder"
    :mode="mode"
    :showSearch="true"
    @change="handleChange"
    @search="handleSearch"
  >
    <template v-for="(option, index) in options">
      <a-select-option :key="option.key" :value="index">
        {{ option.name }}
      </a-select-option>
    </template>
  </a-select>
</template>

<script>
import Enums from "../../enums";

export default {
  model: {
    prop: "value",
    event: "change",
  },
  props: {
    service: {
      type: Object,
    },
    // 类型
    key: {
      type: String,
      default: "name",
    },
    // 类型
    type: {
      type: String,
      default: "",
    },
    // 是否返回完整对象
    whole: {
      type: Boolean,
      default: true,
    },
    // 选择器模式
    mode: {
      type: String,
      default: "default",
    },
    // 数据源
    dataSource: Array,
    // 选中的值
    value: [String, Object, Array],
    // 支持清除
    allowClear: {
      type: Boolean,
      default: true,
    },
    // 默认值
    defaultValue: Object,
    // 是否禁用
    disabled: Boolean,
    // 选择框默认文字
    placeholder: String,
  },
  data() {
    return {
      current: [],
      privateDataSource: [],
    };
  },
  computed: {
    options: function () {
      if (this.service) {
        return this.privateDataSource.map((data) => {
          return { key: data.id, name: data[this.key] };
        });
      }

      if (!this.type || this.type == "") {
        return this.dataSource.map((data) => {
          return { key: data.id, name: data[this.key] };
        });
      }

      const options = [];

      const targets = Enums.getEnumByName(this.type);
      if (!targets) {
        return options;
      }

      for (const targetKey in targets) {
        options.push({ key: targetKey, name: targets[targetKey].description });
      }

      return options;
    },
  },
  watch: {
    value: {
      handler(val) {
        let current = null;

        switch (this.mode) {
          case "default":
            // 单选
            if (!val) {
              current = null;
              break;
            }
            // 判断是否为枚举
            if (!this.type || this.type == "") {
              // 非枚举类型
              current = this.options.findIndex(
                (option) => option.key === val.id
              );
              if (current === -1) {
                current = null;
              }
            } else {
              // 枚举类型
              current = this.options.findIndex((option) => option.key === val);
              if (current === -1) {
                current = null;
              }
            }
            break;
          default:
            // 多选
            if (!val) {
              current = [];
              break;
            }
            current = val.map((v) =>
              this.options.findIndex((option) => option.key === v.id)
            );
            break;
        }

        this.current = current;
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    handleFilter(inputValue, option) {
      console.log(inputValue);
      console.log(option);
      return true;
    },
    handleChange(index) {
      console.log(index);
      let dataSource;

      if (this.service) {
        dataSource = this.privateDataSource;
      } else {
        dataSource = this.dataSource;
      }
      
      let value;

      if (index != undefined) {
        //判断是否为多选
        if (Array.isArray(index)) {
          //多选暂时仅支持DataSource模式
          this.$emit(
            "select",
            index.map((i) => dataSource[i])
          );
          //是否返回完整对象
          if (this.whole) {
            value = index.map((i) => dataSource[i]);
          } else {
            value = index.map((i) => {
              return { id: dataSource[i].id };
            });
          }
        } else {
          this.$emit("select", dataSource[index]);
          //判断是否为枚举类型
          if (!this.type || this.type == "") {
            //非枚举类型
            //是否返回完整对象
            if (this.whole) {
              //返回完整对象
              value = dataSource[index];
            } else {
              //返回简单对象
              value = { id: dataSource[index].id };
            }
          } else {
            //枚举类型
            value = dataSource[index].key;
          }
        }
      } else {
        if (!this.type || this.type == "") {
          //非枚举类型
          value = {};
        } else {
          //枚举类型
          value = undefined;
        }
      }

      this.$emit("change", value);
    },
    async handleSearch(value) {
      const entity = {};
      entity[this.key] = value;

      const result = await this.service.getEntities(entity);

      this.privateDataSource = result;
    },
  },
};
</script>

<style lang="less" scoped>
.select {
  width: 213px;
}
</style>
