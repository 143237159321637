import Vue from 'vue'
import VueRouter from 'vue-router'

import store from "../store";

import Layout from "../layout";
import AuthenticationLayout from "../layout/authentication";

Vue.use(VueRouter)

const routes = [
  {
    path: "/authentication",
    name: "Authentication",
    component: AuthenticationLayout,
    redirect: "/authentication",
    children: [
      {
        path: "/",
        name: "Authentication",
        component: () => import("../views/authentication/index.vue"),
      },
    ],
  },
  {
    path: "/",
    name: "Layout",
    component: Layout,
    redirect: "/",
    children: [
      {
        path: '/',
        name: 'index',
        component: () => import(/* webpackChunkName: "index" */ '../views/index.vue')
      },
      {
        path: "/exhibit",
        name: "exhibit",
        component: () => import(/* webpackChunkName: "exhibit" */ "../views/exhibit/index.vue"),
      },
      {
        path: "/exhibitor",
        name: "exhibitor",
        component: () => import(/* webpackChunkName: "exhibitor" */ "../views/exhibitor/index.vue"),
      },
      {
        path: "/exhibitor-category",
        name: "exhibitor-category",
        component: () => import(/* webpackChunkName: "exhibitor-category" */ "../views/exhibit-category/index.vue"),
      },
      {
        path: "/share-record",
        name: "share-record",
        component: () => import(/* webpackChunkName: "share-record" */ "../views/share-record/index.vue"),
      },
      {
        path: "/exhibit-category",
        name: "exhibit-category",
        component: () => import(/* webpackChunkName: "exhibit-category" */ "../views/exhibit-category/index.vue"),
      },
      {
        path: "/exhibitor/:id",
        name: "exhibitor-profile",
        component: () => import(/* webpackChunkName: "exhibitor-profile" */ "../views/exhibitor/_id/index.vue"),
      },
      {
        path: "/exhibit/:id",
        name: "exhibit-profile",
        component: () => import(/* webpackChunkName: "exhibit-profile" */ "../views/exhibit/_id/index.vue"),
      },
      {
        path: "/article",
        name: "Article",
        component: () => import(/* webpackChunkName: "article" */ "../views/article/index.vue"),
      },
      {
        path: "/article-category",
        name: "ArticleCategory",
        component: () => import(/* webpackChunkName: "article-category" */ "../views/article-category/index.vue"),
      },
      {
        path: "/advertisement",
        name: "Advertisement",
        component: () => import(/* webpackChunkName: "advertisement" */ "../views/advertisement/index.vue"),
      },
      {
        path: "/advertisement-category",
        name: "AdvertisementCategory",
        component: () => import(/* webpackChunkName: "advertisement-category" */ "../views/advertisement-category/index.vue"),
      },
      {
        path: "/document",
        name: "document",
        component: () => import(/* webpackChunkName: "document" */ "../views/document/index.vue"),
      },
      {
        path: "/document-category",
        name: "documentCategory",
        component: () => import(/* webpackChunkName: "documentCategory" */ "../views/document-category/index.vue"),
      },
      {
        path: '/notification',
        name: 'notification',
        component: () => import(/* webpackChunkName: "notification" */ '../views/notification/index.vue')
      },
      {
        path: '/feedback',
        name: 'feedback',
        component: () => import(/* webpackChunkName: "feedback" */ '../views/feedback/index.vue')
      },
      {
        path: '/message',
        name: 'message',
        component: () => import(/* webpackChunkName: "message" */ '../views/message/index.vue')
      },
      {
        path: '/mamber',
        name: 'mamber',
        component: () => import(/* webpackChunkName: "mamber" */ '../views/mamber/index.vue')
      },
      {
        path: '/organization',
        name: 'organization',
        component: () => import(/* webpackChunkName: "organization" */ '../views/organization/index.vue')
      },
      {
        path: '/role',
        name: 'role',
        component: () => import(/* webpackChunkName: "role" */ '../views/role/index.vue')
      },
      {
        path: '/user',
        name: 'user',
        component: () => import( /* webpackChunkName: "user" */'../views/user/index.vue')
      },
      {
        path: "/article-category",
        name: "ArticleCategory",
        component: () => import(/* webpackChunkName: "ArticleCategory" */"../views/article-category/index.vue"),
      },
      {
        path: "/consult",
        name: "Consult",
        component: () => import(/* webpackChunkName: "Consult" */"../views/consult/index.vue"),
      },
      {
        path: "/account",
        name: "account",
        component: () => import(/* webpackChunkName: "account" */"../views/account/index.vue"),
      },
      {
        path: "/account/:id/setting",
        name: "account-setting",
        component: () => import(/* webpackChunkName: "account-setting" */"../views/account/_id/setting/index.vue"),
      },
      {
        path: "/appointment",
        name: "appointment",
        component: () => import(/* webpackChunkName: "appointment" */"../views/appointment/index.vue"),
      },
    ]
  }
]

const router = new VueRouter({
  routes
});
router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title;
  }
  if (store.state.user.id) {
    next();
  } else {
    if (to.fullPath == "/authentication") {
      next();
    } else {
      next({ path: "/authentication" });
    }
  }
});

export default router
