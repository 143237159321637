<template>
  <div>
    <quill-editor
      class="ql-editor"
      v-model="context"
      :ref="quillEditorName"
      :options="options"
      @input="$emit('update:value', $event)"
    >
    </quill-editor>

    <div style="display: none">
      <a-upload
        accept="image/*"
        :action="action"
        :headers="headers"
        :showUploadList="false"
        @change="handleImageUploadChange"
      >
        <i :id="`${quillEditorName}Img`"></i>
      </a-upload>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

import "quill/dist/quill.core.css"; // import styles
import "quill/dist/quill.snow.css"; // for snow theme
import "quill/dist/quill.bubble.css"; // for bubble theme

import * as QuillNamespace from "quill";
let Quill = QuillNamespace;

// 拖拽上传
import { ImageDrop } from "quill-image-drop-module";
// 调整上传图片大小
import ImageResize from "quill-image-resize-module";
// 粘贴图片上传
import { ImageExtend } from "quill-image-paste-module";
// 注册事件~~~~
Quill.register("modules/imageDrop", ImageDrop);
Quill.register("modules/imageResize", ImageResize);
Quill.register("modules/ImageExtend", ImageExtend);

import { quillEditor } from "vue-quill-editor";
import { container, QuillWatch } from "quill-image-extend-module";
import { quillRedefine } from "vue-quill-editor-upload";

Quill.register("modules/ImageResize", ImageResize);
Quill.register("modules/ImageExtend", ImageExtend);

// import oss from "@/utils/ali-oss";

export default {
  model: {
    prop: "value",
    event: "change",
  },
  components: {
    quillEditor,
    // eslint-disable-next-line vue/no-unused-components
    quillRedefine,
    // eslint-disable-next-line vue/no-unused-components
    Quill,
    // eslint-disable-next-line vue/no-unused-components
    QuillWatch,
  },
  props: {
    value: {
      type: String,
    },
    quillEditorName: {
      type: String,
      default: "myQuillEditor",
    },
  },
  data() {
    return {
      limit: 3,
      context: "",
      // 富文本框参数设置
      options: {
        modules: {
          ImageExtend: {
            // 如果不作设置，即{}  则依然开启复制粘贴功能且以base64插入
            name: "", // 图片参数名
            size: 5, // 可选参数 图片大小，单位为M，1M = 1024kb
            action: "", // 服务器地址, 如果action为空，则采用base64插入图片
            // response 为一个函数用来获取服务器返回的具体图片地址
            // 例如服务器返回{code: 200; data:{ url: 'baidu.com'}} 则 return res.data.url
            response: (res) => {
              // 图片上传成功或错误 回调方法 成功后将图片地址return出去
              return res.filePath;
            },
            headers: (xhr) => {
              // 可选参数 设置请求头部

              if (this.authentication && this.authentication.token) {
                xhr.setRequestHeader(
                  "Authorization",
                  `Bearer ${this.authentication.token}`
                );
              }
            },
            sizeError: () => {}, // 图片超过大小的回调
            start: () => {}, // 可选参数 自定义开始上传触发事件
            end: () => {}, // 可选参数 自定义上传结束触发的事件，无论成功或者失败
            error: () => {}, // 可选参数 上传失败触发的事件
            success: () => {}, // 可选参数  上传成功触发的事件
            // change: (xhr, formData) => {
            // xhr.setRequestHeader('myHeader','myValue')
            // formData.append('token', 'myToken')
            // }, // 可选参数 每次选择图片触发，也可用来设置头部，但比headers多了一个参数，可设置formData
          },
          toolbar: {
            // 如果不上传图片到服务器，此处不必配置
            container: container, // container为工具栏，此次引入了全部工具栏，也可自行配置
            handlers: {
              image: (value) => {
                // 劫持原来的图片点击按钮事件 自定义图片上传
                // 触发input框选择图片文件
                if (value) {
                  document.querySelector(`#${this.quillEditorName}Img`).click();
                } else {
                  this.quill.format("image", false);
                }
              },
            },
          },
          ImageResize: {
            //调整上传过后图片大小配置。
            displayStyles: {
              backgroundColor: "black",
              border: "none",
              color: "white",
            },
            modules: ["Resize", "DisplaySize", "Toolbar"],
          },
        },
      },
    };
  },
  computed: {
    ...mapState({
      authentication: (state) => state.authentication,
    }),
    action: function () {
      return process.env.VUE_APP_API_BASE_URL + "/file";
    },
    headers: function () {
      const headers = {};

      if (this.authentication && this.authentication.token) {
        headers.Authorization = `Bearer ${this.authentication.token}`;
      }

      return headers;
    },
  },
  watch: {
    value(newVAlue) {
      this.context = newVAlue;
    },
    context(newValue) {
      this.$emit("change", newValue);
    },
  },
  methods: {
    handleImageUploadChange({ file }) {
      console.log(file);

      if (file.status == "done" && file.response) {
        let quill = this.$refs[this.quillEditorName].quill;

        let length = quill.getSelection().index;
        // 获取光标 设置属性    图片链接
        quill.insertEmbed(length, `image`, `${file.response.url}`);
        // 调整光标到最后
        quill.setSelection(length + 1);
      }
    },
  },

  mounted() {
    this.context = this.value;
  },
};
</script>


<style lang="less" scoped>
.quill-editor {
  // 解决空格被合并问题
  white-space: pre-wrap !important;
  // 解决回显时空格减半问题
  .ql-container {
    white-space: pre-wrap !important;
    .ql-editor {
      white-space: normal !important;
      min-height: 280px;
      padding: 5px 8px;
    }
  }
}
</style>