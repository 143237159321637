/**
 *
 */
const ArticleType = Object.freeze({
    DOCUMENT: {
        key: "DOCUMENT",
        description: "文本新闻"
    },
    LINK: {
        key: "LINK",
        description: "链接新闻"
    },
    VIDEO: {
        key: "VIDEO",
        description: "视频新闻"
    },
})

export default ArticleType
