/**
 * 
 */
 const EntityType = Object.freeze({
    ENUM: {
        key:"ENUM",
        description: "枚举类型"
    },
    RESOURCE
    : {
        key:"RESOURCE",
        description: "对象类型"
    },
})

export default EntityType