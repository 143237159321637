const Language = Object.freeze({
    ZH: {
        key: "ZH",
        description: "中文"
    },
    EN: {
        key: "EN",
        description: "英语"
    }
})

export default Language