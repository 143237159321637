/**
 * 公共枚举--审核进度
 */
const ApproveResult = Object.freeze({
    APPROVED: {
        key:"APPROVED",
        description: "通过"
    },
    UNAPPROVED: {
        key:"UNAPPROVED",
        description: "不通过"
    }
})

export default ApproveResult