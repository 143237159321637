<template>
  <a-breadcrumb :routes="currentRoutes">
    <template slot="itemRender" slot-scope="{ route, routes }">
      <span v-if="!route.path || routes.indexOf(route) === routes.length - 1">
        {{ route.breadcrumbName }}
      </span>
      <router-link v-else :to="route.path">
        {{ route.breadcrumbName }}
      </router-link>
    </template></a-breadcrumb
  >
</template>

<script>
export default {
  props: {
    routes: {
      type: Array,
    },
  },
  computed: {
    currentRoutes: function () {
      //   const routesTree = this.routes
      //     .map((route) => {
      //       return {
      //         path: route.code,
      //         breadcrumbName: route.name,
      //         superior: route.superior,
      //       };
      //     })
      //     .toTree("superior", "children");

      let currentAction = this.routes.find(
        (route) => route.code == this.$route.path
      );

      if (currentAction) {
        const routers = [JSON.parse(JSON.stringify(currentAction))];

        while (currentAction.superior) {
          routers.unshift(JSON.parse(JSON.stringify(currentAction.superior)));

          currentAction = currentAction.superior;
        }

        const rs = routers.map((route) => {
          return {
            path: route.code,
            breadcrumbName: route.name,
            superior: route.superior,
          };
        });

        console.log(rs);

        return rs;
      }

      return [];
    },
  },
  mounted() {},
};
</script>

<style>
</style>