/**
* 视频文件的平台
*/
const VideoPlatform = Object.freeze({
    DOUYIN: {
        key: "DOUYIN",
        description: "抖音"
    },
    KUAISHOU: {
        key: "KUAISHOU",
        description: "快手"
    },
    LOCAL: {
        key: "LOCAL",
        description: "本地视频"
    },

})

export default VideoPlatform