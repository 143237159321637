/**
 * 
 */
const AccountType = Object.freeze({
    EXHIBIT: {
        key: "EXHIBIT",
        description: "展品"
    },
    EXHIBITOR: {
        key: "EXHIBITOR",
        description: "展商"
    },
    USER: {
        key: "USER",
        description: "用户"
    },
    REPLY: {
        key: "REPLY",
        description: "回复"
    }
})

export default AccountType