/**
 * 
 */
 const PlatformType = Object.freeze({
    WECHAT_OPEN: {
        key:"WECHAT_OPEN",
        description: "微信开放平台"
    },
    WECHAT_OFFICIAL_ACCOUNT
    : {
        key:"WECHAT_OFFICIAL_ACCOUNT",
        description: "微信公众号"
    },
    WECHAT_APPLET
    : {
        key:"WECHAT_OFFICIAL_ACCOUNT",
        description: "微信小程序"
    }
})

export default PlatformType