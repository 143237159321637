import qs from "qs";
import request from "../libs/request";

export default class EntityService {
  constructor(name) {
    this.name = name;
  }

  /**
 * 获取Entity数量
 * @param {*} entity
 * @param {*} pageable
 * @returns
 */
  async countEntity(entity) {

    const result = await request({
      url: `/${this.name}`,
      method: "options",
      params: entity,
    });

    return result;
  }

  /**
   * 获取Entity分页
   * @param {*} entity
   * @param {*} pageable
   * @returns
   */
  async getEntityPage(entity, pageable) {

    const pageableStr = qs.stringify(pageable, { arrayFormat: 'repeat' })

    const result = await request({
      url: `/${this.name}s?${pageableStr}`,
      method: "get",
      params: entity,
    });

    return result;
  }

  /**
   *批量添加Entity
   * @param {*} entities
   * @param {*} pageable
   * @returns
   */
  async addEntities(entities) {
    const result = await request({
      url: `/${this.name}s`,
      method: "post",
      data: entities,
    });
    return result;
  }

  /**
   *批量更新Entity
   * @param {*} entities
   * @param {*} pageable
   * @returns
   */
  async updateEntities(entities) {
    const result = await request({
      url: `/${this.name}s`,
      method: "patch",
      data: entities,
    });
    return result;
  }

  /**
   *批量删除Entity
   * @param {*} entities
   * @param {*} pageable
   * @returns
   */
  async deleteEntities(entities) {
    const result = await request({
      url: `/${this.name}s`,
      method: "delete",
      data: entities,
    });
    return result;
  }

  /**
   * 获取Entity导出文件
   * @returns
   */
  async getEntityFile(entity, sort) {

    const sortStr = qs.stringify(sort, { arrayFormat: 'repeat' })

    const result = await request({
      url: `/${this.name}s/file?${sortStr}`,
      method: "get",
      params: entity,
      responseType: "blob",
    });

    return result;
  }

  /**
   * 获取全部Entity
   * @param {*} entity
   * @param {*} sort
   * @returns 
   */
  async getEntities(entity, sort) {

    const sortStr = qs.stringify(sort, { arrayFormat: 'repeat' })

    const result = await request({
      url: `/${this.name}?${sortStr}`,
      method: "get",
      params: entity,
    });

    return result;
  }

  /**
   * 根据id获取entity
   * @param {*} entity
   * @param {*} pageable
   * @returns
   */
  async getEntity(entity) {
    if (!entity.id) {
      throw new Error("参数必须包含id");
    }

    const result = await request({
      url: `/${this.name}/${entity.id}`,
      method: "get",
    });

    return result;
  }

  /**
   * 添加Entity
   * @param {*} entity
   * @param {*} pageable
   * @returns
   */
  async addEntity(entity) {
    const result = await request({
      url: `/${this.name}`,
      method: "post",
      data: entity,
    });

    return result;
  }

  /**
   * 更新Entity
   * @param {*} entity
   * @param {*} pageable
   * @returns
   */
  async updateEntity(entity) {
    if (!entity.id) {
      throw new Error("参数必须包含id");
    }

    const result = await request({
      url: `/${this.name}/${entity.id}`,
      method: "patch",
      data: entity,
    });

    return result;
  }

  /**
   * 删除Entity
   * @param {*} entity
   * @param {*} pageable
   * @returns
   */
  async deleteEntity(entity) {
    if (!entity.id) {
      throw new Error("参数必须包含id");
    }

    const result = await request({
      url: `/${this.name}/${entity.id}`,
      method: "delete",
    });

    return result;
  }
}
