/**
 * 点赞的东西的枚举
 */
 const LikedType = Object.freeze({
    EXHIBIT: {
        key: "EXHIBIT",
        description: "展品"
    },
    EXHIBITOR: {
        key: "EXHIBITOR",
        description: "展商"
    }
})

export default LikedType