<template>
  <fullscreen :fullscreen.sync="fullscreen">
    <div>
      <a-table
        :columns="actualColumns"
        :data-source="service ? entities : dataSource"
        :loading="loading"
        :pagination="pagination"
        :rowKey="rowKey"
        :rowSelection="selectable ? rowSelection : null"
        :size="privateSize"
        :scroll="scroll"
        @change="handleTableChange"
      >
        <template slot="title">
          <a-row justify="space-between" type="flex">
            <a-col>{{ title }}</a-col>
            <a-col>
              <a-space>
                <a-button
                  v-if="addable"
                  type="primary"
                  icon="plus"
                  @click="handleAddClick"
                  >新增</a-button
                >
                <a-divider type="vertical" />
                <a-icon type="redo" @click="handleRefreshClick" />
                <a-dropdown>
                  <a-icon type="column-height" />
                  <a-menu slot="overlay">
                    <a-menu-item
                      key="default"
                      @click="handleColumnHeightChange('default')"
                    >
                      默认
                    </a-menu-item>
                    <a-menu-item
                      key="middle"
                      @click="handleColumnHeightChange('middle')"
                    >
                      中等
                    </a-menu-item>
                    <a-menu-item
                      key="small"
                      @click="handleColumnHeightChange('small')"
                    >
                      紧凑
                    </a-menu-item>
                  </a-menu>
                </a-dropdown>

                <a-popover placement="bottom" trigger="click">
                  <a-icon type="setting"> </a-icon>
                  <template slot="title">
                    <a-row align="middle" justify="space-between" type="flex">
                      <a-col>
                        <a-checkbox
                          :indeterminate="indeterminate"
                          :checked="columnCheckedAll"
                          @change="handleColumnCheckAllCheckboxChange"
                        >
                          全选
                        </a-checkbox>
                      </a-col>
                      <a-col>
                        <a-button
                          type="link"
                          @click="handleColumnCheckboxGroupReset"
                        >
                          重置
                        </a-button>
                      </a-col>
                    </a-row>
                  </template>
                  <template slot="content">
                    <a-checkbox-group
                      v-model="checkedcolumnKeys"
                      :options="columnOptions"
                      @change="handleColumnCheckboxChange"
                    >
                      <span slot="label" slot-scope="{ value }">
                        {{ value }}
                      </span>
                    </a-checkbox-group>
                  </template>
                </a-popover>
                <a-icon
                  :type="fullscreen ? 'fullscreen-exit' : 'fullscreen'"
                  @click="handleFullScreenClick()"
                />
              </a-space>
            </a-col>
          </a-row>
        </template>
        <!-- 预制搜索筛选ICON -->
        <template slot="filterSearchIcon" slot-scope="filtered">
          <a-icon
            type="search"
            :style="{ color: filtered ? '#108ee9' : undefined }"
          />
        </template>
        <!-- 预制搜索筛选 -->
        <div
          slot="filterDropdown"
          slot-scope="{
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
            column,
          }"
          style="padding: 8px"
        >
          <a-input
            v-ant-ref="(c) => (searchInput = c)"
            :placeholder="`Search ${column.dataIndex}`"
            :value="selectedKeys[0]"
            style="width: 188px; margin-bottom: 8px; display: block"
            @change="
              (e) => setSelectedKeys(e.target.value ? [e.target.value] : [])
            "
            @pressEnter="
              () => handleSearch(selectedKeys, confirm, column.dataIndex)
            "
          />
          <a-button
            type="primary"
            icon="search"
            size="small"
            style="width: 90px; margin-right: 8px"
            @click="() => handleSearch(selectedKeys, confirm, column.dataIndex)"
          >
            搜索
          </a-button>
          <a-button
            size="small"
            style="width: 90px"
            @click="() => handleReset(clearFilters)"
          >
            重置
          </a-button>
        </div>
        <!-- 预制搜索筛选高亮 -->
        <template slot="customRender" slot-scope="text, record, index, column">
          <span v-if="searchText && searchedColumn === column.dataIndex">
            <template
              v-for="(fragment, i) in text
                .toString()
                .split(new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i'))"
            >
              <mark
                v-if="fragment.toLowerCase() === searchText.toLowerCase()"
                :key="i"
                class="highlight"
                >{{ fragment }}</mark
              >
              <template v-else>{{ fragment }}</template>
            </template>
          </span>
          <template v-else>
            {{ text }}
          </template>
        </template>
        <!-- 预制富文本查看处理 -->
        <template slot="html" slot-scope="text">
          <a
            v-if="text"
            href="javascript:;"
            @click="handleContentPreviewClick(text)"
          >
            查看
          </a>
          <span v-else>/</span>
        </template>
        <!-- 预制大文本查看处理 -->
        <template slot="text" slot-scope="text">
          <a
            v-if="text"
            href="javascript:;"
            @click="handleContentPreviewClick(text)"
          >
            查看
          </a>
          <span v-else>/</span>
        </template>
        <!-- 预制大文本查看处理 -->
        <template slot="json" slot-scope="text">
          <a
            v-if="text"
            href="javascript:;"
            @click="handleJsonPreviewClick(text)"
          >
            查看
          </a>
          <span v-else>/</span>
        </template>
        <!-- 预制图片预览处理 -->
        <template slot="picture" slot-scope="text">
          <img
            v-if="text"
            style="cursor: pointer"
            :src="text"
            width="100"
            alt=""
            srcset=""
            @click="handlePicturePreviewClick(text)"
          />
          <span v-else>/</span>
        </template>
        <!-- 预制视频预览处理 -->
        <template slot="video" slot-scope="text">
          <a v-if="text" @click="handleVideoPreviewClick(text)"
            ><a-icon type="video-camera" />点击预览</a
          >
          <span v-else>/</span>
        </template>
        <!-- 预制外链处理 -->
        <span slot="link" slot-scope="text">
          <a v-if="text" target="_blank" :href="text"
            ><a-icon type="link" />点击跳转</a
          >
          <span v-else>/</span>
        </span>
        <!-- 预制权重处理 -->
        <span slot="sequence" slot-scope="text, record">
          <a @click="handleSortClick(record)">{{
            text != undefined ? text : "设置"
          }}</a>
        </span>
        <!-- 预制时间格式化处理 -->
        <template slot="dateTime" slot-scope="text">
          {{ new Date(text).format("yyyy-MM-dd hh:mm:ss") }}
        </template>
        <!-- Enabled开关 -->
        <template slot="enabled" slot-scope="text, record">
          <a-switch
            v-model="record.enabled"
            @click="handleEnableChange(record)"
          ></a-switch>
        </template>
        <!-- 自定义操作栏 -->
        <template slot="action" slot-scope="text, record, index">
          <slot
            name="action"
            :text="text"
            :record="record"
            :index="index"
          ></slot>
        </template>
      </a-table>
    </div>
    <a-modal
      :footer="null"
      title="图片预览"
      :visible="picturePreviewVisible"
      @cancel="handlePicturePreviewClose"
      style="width: 30vw"
    >
      <img :src="picture" style="width: 100%" />
    </a-modal>
    <a-modal
      :footer="null"
      title="视频预览"
      :visible="videoPreviewVisible"
      @cancel="handleVideoPreviewClose"
      style="width: 50vw"
    >
      <video :src="video" style="width: 100%" controls></video>
    </a-modal>
    <a-modal
      :footer="null"
      title="内容预览"
      :visible="contentPreviewVisible"
      @cancel="handleContentPreviewClose"
      style="width: 50vw"
    >
      <div v-html="content"></div>
    </a-modal>
    <a-modal
      :footer="null"
      title="JSON"
      :visible="jsonPreviewVisible"
      @cancel="handleJsonPreviewClose"
      style="width: 50vw"
    >
      <vue-json-editor
        v-model="json"
        :show-btns="false"
        :expandedOnStart="true"
        mode="view"
        lang="zh"
      ></vue-json-editor>
    </a-modal>
    <a-modal
      :confirm-loading="loading"
      title="排序"
      :visible="sortModalVisible"
      @cancel="handleSortModalCancel"
      @ok="handleSortModalOk"
    >
      <a-form-model :model="sortTarget">
        <a-form-model-item
          label="排序"
          prop="sequence"
          :label-col="{ span: 4 }"
          :wrapper-col="{ span: 14 }"
        >
          <a-input-number
            v-model="sortTarget.sequence"
            :defaultValue="1"
            :min="0"
          ></a-input-number>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </fullscreen>
</template>

<script>
const defaultPagination = {
  current: 1,
  pageSize: 10,
  showQuickJumper: true,
  showSizeChanger: true,
  total: 0,
};

export default {
  props: {
    title: String,
    columns: Array,
    loading: {
      type: Boolean,
      default: false,
    },
    pagination: {
      type: [Boolean, Object],
      default: function () {
        return JSON.parse(JSON.stringify(defaultPagination));
      },
    },
    rowKey: {
      type: String,
      default: "id",
    },
    defaultHiddenColumns: {
      type: Array,
      default: function () {
        return ["createTime", "creater", "updateTime", "updater"];
      },
    },
    addable: {
      type: Boolean,
      default: true,
    },
    selectable: {
      type: Boolean,
      default: false,
    },
    scroll: {
      type: Object,
    },
    rowSelection: {
      type: Object,
      // default: function () {
      //   return JSON.parse(JSON.stringify(this.defaultRowSelection));
      // },
    },
    size: {
      type: String,
      default: "default",
    },
    service: {
      type: Object,
    },
    entity: {
      type: Object,
      default: () => {
        return {};
      },
    },
    filters: {
      type: Object,
      default: () => {
        return {};
      },
    },
    sorter: {
      type: Array,
      default: () => {
        return [
          {
            field: "createTime",
            order: "DESC",
          },
        ];
      },
    },
  },
  data() {
    return {
      privateLoading: this.loading,
      //表格大小
      privateSize: this.size,
      //列选择器属性
      indeterminate: false,
      columnCheckedAll: false,
      checkedcolumnKeys: [],
      //全屏
      fullscreen: false,
      //图片预览
      picturePreviewVisible: false,
      picture: null,
      //视频预览
      videoPreviewVisible: false,
      video: null,
      // 内容预览
      contentPreviewVisible: false,
      content: "",
      // JSON预览
      jsonPreviewVisible: false,
      json: "",
      // 排序
      sortModalVisible: false,
      sortTarget: {},
      //表格内容
      entities: [],
      selectedRowKeys: [],
      defaultRowSelection: {
        selectedRowKeys: this.selectedRowKeys,
        type: "checkbox",
        onChange: this.handlerSelectedChange,
      },
    };
  },
  computed: {
    actualColumns: function () {
      return [
        {
          dataIndex: "index",
          title: "序号",
          customRender: (text, record, index) =>
            (this.pagination.current - 1) * this.pagination.pageSize +
            index +
            1,
        },
        ...this.columns.filter(
          (column) =>
            this.checkedcolumnKeys.findIndex(
              (key) => key == column.dataIndex || key == column.key
            ) !== -1
        ),
      ];
    },
    columnOptions: function () {
      return this.columns.map((column) => {
        return { label: column.title, value: column.dataIndex || column.key };
      });
    },
  },
  watch: {
    // entity: {
    //   handler: async function () {
    //     try {
    //       this.$emit("update:loading", true);

    //       await this.handleParamChange();
    //     } catch (error) {
    //       this.$message.error(
    //         error.response ? error.response.data.message : error.message
    //       );
    //     } finally {
    //       this.$emit("update:loading", false);
    //     }
    //   },
    //   immediate: true,
    //   deep: true,
    // },
    filters: {
      handler: async function () {
        try {
          this.$emit("update:loading", true);

          await this.handleParamChange();
        } catch (error) {
          this.$message.error(
            error.response ? error.response.data.message : error.message
          );
        } finally {
          this.$emit("update:loading", false);
        }
      },
      immediate: false,
      deep: true,
    },
    // sorter: {
    //   handler: async function () {
    //     try {
    //       this.$emit("update:loading", true);

    //       await this.handleParamChange();
    //     } catch (error) {
    //       this.$message.error(
    //         error.response ? error.response.data.message : error.message
    //       );
    //     } finally {
    //       this.$emit("update:loading", false);
    //     }
    //   },
    //   immediate: false,
    //   deep: true,
    // },
    loading: {
      handler: async function (val) {
        this.privateLoading = val;
      },
      immediate: true,
      deep: true,
    },
    size: {
      handler: async function (val) {
        this.privateSize = val;
      },
      immediate: true,
      deep: true,
    },
    columns: {
      handler: async function () {
        this.handleColumnCheckboxGroupReset();
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    handleAddClick() {
      this.$emit("add", true);
    },
    handleFullScreenClick() {
      this.fullscreen = !this.fullscreen;
    },
    /**
     * 刷新按钮点击事件
     */
    async handleRefreshClick() {
      try {
        this.$emit("update:loading", true);
        await this.handleParamChange();
      } catch (error) {
        this.$message.error(
          error.response ? error.response.data.message : error.message
        );
      } finally {
        //终止loading
        this.$emit("update:loading", false);
      }
    },
    /**
     * 调整表格间距按钮点击事件
     */
    handleColumnHeightChange(size) {
      this.privateSize = size;
      this.$emit("update:size", size);
    },
    handleColumnCheckAllCheckboxChange(e) {
      Object.assign( {
        checkedcolumnKeys: e.target.checked
          ? this.columnOptions.map((column) => column.value)
          : [],
        indeterminate: false,
        columnCheckedAll: e.target.checked,
      });
    },
    /**
     * 表格列重置
     */
    handleColumnCheckboxGroupReset() {
      this.checkedcolumnKeys = this.columnOptions
        .filter(
          (column) => this.defaultHiddenColumns.indexOf(column.value) == -1
        )
        .map((column) => column.value);
      this.columnCheckedAll = true;
      this.indeterminate = false;
    },
    handleColumnCheckboxChange(checkedColumns) {
      this.indeterminate =
        !!checkedColumns.length &&
        checkedColumns.length < this.columnOptions.length;
      this.columnCheckedAll =
        checkedColumns.length === this.columnOptions.length;
    },
    handlerSelectedChange(selectedRowKeys, selectedRows) {
      console.log(selectedRowKeys);
      console.log(selectedRows);
      this.$emit("change", selectedRowKeys);
    },
    handleVideoPreviewClick(text) {
      this.videoPreviewVisible = true;
      this.video = text;
    },
    handleVideoPreviewClose() {
      this.videoPreviewVisible = false;
      this.picture = null;
    },
    handlePicturePreviewClick(text) {
      this.picturePreviewVisible = true;
      this.picture = text;
    },
    handlePicturePreviewClose() {
      this.picturePreviewVisible = false;
      this.picture = null;
    },
    handleContentPreviewClick(text) {
      this.contentPreviewVisible = true;
      this.content = text;
    },
    handleContentPreviewClose() {
      this.contentPreviewVisible = false;
      this.content = null;
    },
    handleJsonPreviewClick(text) {
      this.jsonPreviewVisible = true;
      this.json = text;
    },
    handleJsonPreviewClose() {
      this.jsonPreviewVisible = false;
      this.json = null;
    },
    handleSortClick(record) {
      this.sortTarget = { id: record.id, sequence: record.sequence };
      this.sortModalVisible = true;
    },
    handleSortModalCancel() {
      this.sortTarget = {};
      this.sortModalVisible = false;
    },
    async handleSortModalOk() {
      try {
        this.$emit("update:loading", true);

        await this.service.updateEntity({
          id: this.sortTarget.id,
          sequence: this.sortTarget.sequence,
        });
      } catch (error) {
        this.$message.error(
          error.response ? error.response.data.message : error.message
        );
      } finally {
        this.sortTarget = {};
        this.sortModalVisible = false;

        //更新数据
        await this.handleParamChange();
        //终止loading
        this.$emit("update:loading", false);
      }
    },
    async handleEnableChange(record) {
      try {
        //开始loading
        this.$emit("update:loading", true);
        //更新enable
        await this.service.updateEntity({
          id: record.id,
          enabled: record.enabled,
        });
      } catch (error) {
        this.$message.error(
          error.response ? error.response.data.message : error.message
        );
      } finally {
        //更新数据
        await this.handleParamChange();
        //终止loading
        this.$emit("update:loading", false);
      }
    },
    async handleParamChange() {
      await this.getEntityPage(this.entity, this.pagination, [], {});
    },
    async handleTableChange(pagination, filters, sorter) {
      try {
        this.$emit("update:loading", true);

        if (typeof pagination === "boolean" && !pagination) {
          await this.getEntities(this.entity, filters, sorter);
        } else {
          await this.getEntityPage(this.entity, pagination, filters, sorter);
        }
      } catch (error) {
        this.$message.error(
          error.response ? error.response.data.message : error.message
        );
      } finally {
        this.$emit("update:pagination", pagination);
        this.$emit("update:filters", filters);
        this.$emit("update:sorter", sorter);
        this.$emit("update:loading", false);
      }
    },
    async getEntityPage(entity, pagination, filters, sorter) {
      const entityParams = {
        ...entity,
      };

      const pageable = {
        page: pagination.current - 1,
        size: pagination.pageSize,
        sort: [],
      };

      // 表格自身过滤条件
      if (filters) {
        for (const key in filters) {
          const param = filters[key];
          entityParams[key] = param.length > 2 ? param : param[0];
        }
      }

      // 外部传入过滤条件
      if (this.filters) {
        for (const key in this.filters) {
          const param = this.filters[key];
          entityParams[key] = param;
        }
      }

      // 表格自身排序
      if (sorter && sorter.order) {
        pageable.sort.push(
          `${sorter.field},${sorter.order.substring(
            0,
            sorter.order.length - 3
          )}`
        );
      }

      //外部传入排序
      if (this.sorter.length > 0) {
        for (const sort of this.sorter) {
          pageable.sort.push(
            `${sort.field},${sort.order ? sort.order : "DESC"}`
          );
        }
      }

      const result = await this.service.getEntityPage(entityParams, pageable);
      this.entities = result.content;
      this.pagination.current = result.number + 1;
      this.pagination.pageSize = result.size;
      this.pagination.total = result.totalElements;
    },
    async getEntities(entity, filters, sorter) {
      const sortable = {};

      if (filters) {
        for (const key in filters) {
          const param = filters[key];
          entity[key] = param.length > 2 ? param : param[0];
        }
      }

      if (sorter && sorter.order) {
        sortable.sort = `${sorter.field},${sorter.order.substring(
          0,
          sorter.order.length - 3
        )}`;
      }

      const result = await this.service.getEntities(entity, sortable);

      this.entities = result;
    },
    createHtmlModal({ title, content }) {
      const h = this.$createElement;
      this.$info({
        content: h("div", { domProps: { innerHTML: content } }),
        title: title,
        width: "50%",
        onOk() {},
      });
    },
  },
};
</script>

<style lang="less" scoped>
.ant-table-title {
  padding: 0 0 16px 0;
}
.ant-checkbox-wrapper {
  display: block !important;
}
.fullscreen {
  background-color: #ffffff;
  padding: 1rem;
}
</style>
