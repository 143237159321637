import Vue from 'vue'

import '../index.less'

import DatePicker from '../components/date-picker'
import Export from '../components/export'
import Radio from '../components/radio'
import Select from '../components/select'
import TreeSelect from "../components/tree-select";
import Table from '../components/table'
import Tag from '../components/tag'
import Upload from "../components/upload";
import Breadcrumb from "../components/breadcrumb"
import IconSelector from "../components/icon-selector/index.vue"

import RichTextEditor from "../components/rich-text-editor"
Vue.component('y-rich-text-editor', RichTextEditor)

Vue.component('y-date-picker', DatePicker)
Vue.component('y-export', Export)
Vue.component('y-radio', Radio)
Vue.component('y-select', Select)
Vue.component('y-tree-select', TreeSelect)
Vue.component('y-table', Table)
Vue.component('y-tag', Tag)
Vue.component('y-upload', Upload)
Vue.component('y-breadcrumb', Breadcrumb)
Vue.component('y-icon-selector', IconSelector)