/**
 * 
 */
 const NavigationType = Object.freeze({
    LINK: {
        key:"LINK",
        description: "链接"
    },
    PAGE: {
        key:"PAGE",
        description: "页面"
    },
})

export default NavigationType