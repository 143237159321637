/**
 *组织的类型
 */
 const OrganizationType = Object.freeze({
    AUDIENCE: {
        key:"AUDIENCE",
        description: "观众"
    },
    EXHIBITOR: {
        keyL:"EXHIBITOR",
        description: "展商"
    },
    MEDIA: {
        key:"MEDIA",
        description: "MEDIA"
    },
})

export default OrganizationType