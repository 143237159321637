/**
 * 
 */
const ActionType = Object.freeze({
    MENU: {
        key:"MENU",
        description: "菜单"
    },
    LINK: {
        key:"LINK",
        description: "链接"
    },
    BUTTON: {
        key:"BUTTON",
        description: "按钮"
    }
})

export default ActionType