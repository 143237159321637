import AccountType from "./account-type";
import ActionType from "./action-type";
import ArticleType from "./article-type";
import AdvertisementType from './advertisement-type';
import ApproveResult from "./approve-result";
import CollectedType from "./collected-type";
import DocumentFormat from "./document-format";
import DocumentType from "./document-type";
import ExhibitType from "./exhibit-type";
import FileRecordType from "./file-category";
import Gender from "./gender";
import LikedType from "./liked-type";
import NavigationType from "./navigation-type";
import Platform from "./platform";
import Language from "./language";
import ConsultType from "./consultType";
import VideoPlatform from "./video-platform";
import MessageStatus from "./message-status";
import OrganizationType from "./organization-type";
import PavilionType from "./pavilion-type";
import EnquiryType from "./enquiry-type";
import ApplicationCategory from "./application-category";
import ApplicationType from "./application-type";
import FolderType from "./folder-type";
import BannerType from "./banner-type";
import EntityType from "./entity-type";
import PlatformType from "./platform-type";
import Progress from "./progress";
import CommodityType from "./commodity-type";
import ComponentType from "./component-type";
import NotificationType from "./notification-type";

import MessageType from "./message-type";

const getEnumByName = function (name) {

    if (Object.hasOwnProperty.call(enums, name)) {

        const target = this[name]

        return target
    } else {

        return undefined
    }
}


const getEnumValueByKey = function (name, key) {

    const target = this.getEnumByName(name)

    if (!target) {
        return undefined
    }

    for (const k in target) {
        if (k == key) {
            return target[key]
        }
    }
}


const getFiltersByName = function (name) {

    const filters = []

    const target = this.getEnumByName(name)

    if (!target) {
        return undefined
    }

    console.log(target);

    for (const key in target) {

        filters.push({ text: target[key].description, value: key })
    }

    return filters
}


const enums = {
    AccountType,
    ActionType,
    ArticleType,
    AdvertisementType,
    CollectedType,
    DocumentFormat,
    DocumentType,
    ExhibitType,
    FileRecordType,
    Gender,
    LikedType,
    NavigationType,
    Platform,
    Language,
    ConsultType,
    NotificationType,
    VideoPlatform,
    ApproveResult,
    MessageStatus,
    OrganizationType,
    PavilionType,
    EnquiryType,
    ApplicationCategory,
    ApplicationType,
    FolderType,
    BannerType,
    EntityType,
    PlatformType,
    Progress,
    CommodityType,
    ComponentType,
    MessageType,
    getEnumByName,
    getEnumValueByKey,
    getFiltersByName
}

export default enums
