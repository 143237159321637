<template>
  <a-dropdown v-if="name" placement="bottomRight">
    <span class="user">
      <a-avatar class="avatar" icon="user" size="default" :src="portrait || ''">
        {{ name }}
      </a-avatar>
      <span>{{ name }}</span>
    </span>
    <template v-slot:overlay>
      <a-menu class="ant-pro-drop-down menu" :selected-keys="[]">
        <!-- <a-menu-item v-if="menu" key="center" @click="handleToCenter">
          <a-icon type="user" />
          {{ $t("menu.account.center") }}
        </a-menu-item> -->
        <a-menu-item v-if="menu" key="settings" @click="handleToSettings">
          <a-icon type="setting" />
          {{ $t("menu.account.settings") }}
        </a-menu-item>
        <a-menu-divider v-if="menu" />
        <a-menu-item key="logout" @click="handleLogout">
          <a-icon type="logout" />
          {{ $t("menu.account.logout") }}
        </a-menu-item>
      </a-menu>
    </template>
  </a-dropdown>
  <span v-else>
    <a-spin size="small" :style="{ marginLeft: 8, marginRight: 8 }" />
  </span>
</template>

<script>
// import { Modal } from "ant-design-vue";

export default {
  name: "AvatarDropdown",
  props: {
    id: {
      type: String,
    },
    name: {
      type: String,
    },
    portrait: {
      type: String,
    },
    menu: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    handleToCenter() {
      this.$router.push({ path: `/user/${this.id}` });
    },
    handleToSettings() {
      this.$router.push({ path: `/account/${this.id}/setting` });
    },
    handleLogout() {
      window.sessionStorage.removeItem("vuex");
      this.$router.push("/authentication");
      // Modal.confirm({
      //   title: this.$t("layouts.usermenu.dialog.title"),
      //   content: this.$t("layouts.usermenu.dialog.content"),
      //   onOk: () => {
      //     // return new Promise((resolve, reject) => {
      //     //   setTimeout(Math.random() > 0.5 ? resolve : reject, 1500)
      //     // }).catch(() => console.log('Oops errors!'))
      //     return this.$store.dispatch("Logout").then(() => {
      //       this.$router.push({ name: "login" });
      //     });
      //   },
      //   onCancel() {},
      // });
    },
  },
};
</script>

<style lang="less" scoped>
.user {
  .avatar {
    margin-right: 8px;
  }
}

.ant-pro-drop-down {
  :deep(.action)  {
    margin-right: 8px;
  }
  :deep( .ant-dropdown-menu-item) {
    min-width: 160px;
  }
}
</style>
