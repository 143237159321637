const ConsultType = Object.freeze({
    LAW: {
        key: "LAW",
        description: "法律咨询"
    },
    SECURITYINJURY: {
        key: "SECURITYINJURY",
        description: "工伤社保咨询"
    }
})

export default ConsultType