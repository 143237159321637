<template>
  <a-layout class="layout">
    <a-layout-sider class="sider">
      <div class="logo">金桥集团门户</div>
      <a-menu mode="inline" @click="handleClick" class="menu">
        <template v-for="menu in menus">
          <!-- 菜单组 -->
          <a-sub-menu
            v-if="
              Array.isArray(menu.subordinates) && menu.subordinates.length > 0
            "
            :key="menu.id"
          >
            <div slot="title">
              <a-icon v-if="menu.icon" :type="menu.icon" />
              {{ menu.name }}
            </div>
            <template v-for="subordinate in menu.subordinates">
              <!-- 菜单组 -->
              <a-sub-menu
                v-if="
                  Array.isArray(subordinate.subordinates) &&
                  subordinate.subordinates.length > 0
                "
                :key="subordinate.id"
              >
                <div slot="title">
                  <a-icon v-if="subordinate.icon" :type="subordinate.icon" />
                  {{ subordinate.name }}
                </div>
                <template v-for="sub in subordinate.subordinates">
                  <a-menu-item :key="sub.actionKey">
                    <a-icon v-if="sub.icon" :type="sub.icon" />
                    {{ sub.name }}
                  </a-menu-item>
                </template>
              </a-sub-menu>
              <!-- 菜单项 -->
              <a-menu-item v-else :key="subordinate.actionKey">
                <a-icon v-if="subordinate.icon" :type="subordinate.icon" />
                {{ subordinate.name }}
              </a-menu-item>
            </template>
          </a-sub-menu>
          <!-- 菜单项 -->
          <a-menu-item v-else :key="menu.actionKey">
            <a-icon v-if="menu.icon" :type="menu.icon" />
            {{ menu.name }}
          </a-menu-item>
        </template>
      </a-menu>
    </a-layout-sider>
    <a-layout class="body">
      <a-layout-header class="header">
        <avatar-dropdown
          :id="account.id"
          :menu="true"
          :name="user.name || account.name || account.username"
          :portrait="user.portrait || account.portrait"
        />
      </a-layout-header>
      <a-layout-content class="content">
        <!-- <y-breadcrumb class="page-header" :routes="actions"></y-breadcrumb> -->

        <!-- <a-page-header
          class="page-header"
          title="Title"
          :breadcrumb="{ props: { routes } }"
          :subTitle="'当前所在位置：' + selectMenuName"
          @back="() => $router.go(-1)"
        /> -->
        <div class="page-content">
          <router-view></router-view>
        </div>
      </a-layout-content>
    </a-layout>
  </a-layout>
</template>

<script>
import { mapState } from "vuex";
// import routerService from '../router/index'
import ActionService from "../services/action";
// import UserService from "../services/user";
import AvatarDropdown from "@/components/avatar-dropdown/index.vue";
import EventBus from "@/libs/event-bus";

export default {
  components: {
    AvatarDropdown,
  },
  data() {
    return {
      actions: [],
      menus: [],
      selectMenuName: "",
    };
  },
  computed: {
    ...mapState({
      account: (state) => state.account,
      user: (state) => state.user,
    }),
  },
  methods: {
    handlePageHeader() {
      try {
        var test = window.location.hash;
        var url = test.slice(1);
      } catch (error) {
        error.response ? error.response.data.message : error.message;
      } finally {
        for (var i = 0; i < this.menus.length; i++) {
          if (this.menus[i].subordinates) {
            for (var j = 0; j < this.menus[i].subordinates.length; j++) {
              if (this.menus[i].subordinates[j].subordinates) {
                for (
                  var k = 0;
                  k < this.menus[i].subordinates[j].subordinates.length;
                  k++
                ) {
                  if (
                    this.menus[i].subordinates[j].subordinates[k].actionKey ==
                    url
                  ) {
                    this.selectMenuName =
                      this.menus[i].subordinates[j].subordinates[k].name;
                  }
                }
              } else {
                if (this.menus[i].subordinates[j].actionKey == url) {
                  this.selectMenuName = this.menus[i].subordinates[j].name;
                }
              }
            }
          } else {
            if (this.menus[i].actionKey == url) {
              this.selectMenuName = this.menus[i].name;
            }
          }
        }
      }
      console.log(this.selectMenuName);
    },
    handleClick({ key }) {
      try {
        for (var i = 0; i < this.menus.length; i++) {
          if (this.menus[i].subordinates) {
            for (var j = 0; j < this.menus[i].subordinates.length; j++) {
              if (this.menus[i].subordinates[j].subordinates) {
                for (
                  var k = 0;
                  k < this.menus[i].subordinates[j].subordinates.length;
                  k++
                ) {
                  if (
                    this.menus[i].subordinates[j].subordinates[k].actionKey ==
                    key
                  ) {
                    this.selectMenuName =
                      this.menus[i].subordinates[j].subordinates[k].name;
                  }
                }
              } else {
                if (this.menus[i].subordinates[j].actionKey == key) {
                  this.selectMenuName = this.menus[i].subordinates[j].name;
                }
              }
            }
          } else {
            if (this.menus[i].actionKey == key) {
              console.log(this.menus[i]);
              this.selectMenuName = this.menus[i].name;
            }
          }
        }
        if (key === this.$route.path) return;
        this.$router.push(key);
      } catch (error) {
        this.$message.error(
          error.response ? error.response.data.message : error.message
        );
      } finally {
        // this.$nextTick(function() {
        //   var test = window.location.hash
        //   alert(test)
        // })
      }
    },
    async getActions(action) {
      // const result = await UserService.getUserActions(this.user, action);

      const result = await ActionService.getEntities(action, {
        sort: "sequence,DESC",
      });

      // result.sort((a, b) => a.sequence - b.sequence);

      this.actions = result;
      this.menus = result.toTree();
    },
  },
  async created() {
    EventBus.$on("getActions", (target) => {
      this.getActions({ type: "MENU", enabled: true });
      console.log(target);
    });
  },
  async mounted() {
    await this.getActions({ type: "MENU", enabled: true });

    await this.handlePageHeader();
  },
};
</script>
<style lang="less" scoped>
.layout {
  max-height: 100%;
  min-height: 100%;

  .sider {
    background-color: #fff !important;
    height: 100vh;

    .logo {
      height: 64px;
      width: 100%;
      line-height: 64px;
      text-align: center;
    }
    .menu {
      width: 100%;
      height: calc(~"100vh - 64px");
      overflow-y: hidden;
      overflow-x: hidden;
    }

    .menu:hover {
      overflow-y: overlay;
    }

    .menu::-webkit-scrollbar {
      width: 6px;
      height: 5px;
    }
    .menu::-webkit-scrollbar-thumb {
      border-radius: 10px;
      box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
      background: rgba(0, 0, 0, 0.2);
    }
    .menu::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
      border-radius: 6px;
      background: rgba(0, 0, 0, 0.1);
    }
  }

  .body {
    max-height: 100vh;
    min-height: 100vh;

    .header {
      background-color: #fff !important;
      display: flex;
      justify-content: flex-end;
    }

    .content {
      height: 100vh;
      overflow-y: hidden;

      .page-header {
        background-color: #fff !important;
        padding: 12px 12px 12px 27px;
        font-size: 16px;
      }

      .page-content {
        height: calc(~"100vh - 64px");
        overflow-y: hidden;
      }

      .page-content:hover {
        overflow-y: overlay;
      }

      .page-content::-webkit-scrollbar {
        width: 6px;
        height: 5px;
      }

      .page-content::-webkit-scrollbar-thumb {
        border-radius: 10px;
        box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
        background: rgba(0, 0, 0, 0.2);
      }

      .page-content::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
        border-radius: 6px;
        background: rgba(0, 0, 0, 0.1);
      }
    }
  }
}
</style>
