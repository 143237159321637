/**
 * 收藏的东西的枚举
 */
 const CollectedType = Object.freeze({
    EXHIBIT: {
        key:"EXHIBIT",
        description: "展品"
    },
    EXHIBITOR: {
        key:"EXHIBITOR",
        description: "展商"
    }
})

export default CollectedType