import Vue from 'vue'
import Vuex from 'vuex'
import createLogger from 'vuex/dist/logger'
import createPersistedState from "vuex-persistedstate";

import AuthenticationService from "@/services/authentication.js"
import AccountService from "@/services/account.js"

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    authentication: {},
    account: {},
    user: {},
  },
  getters: {
  },
  mutations: {
    setAuthentication(state, payload) {
      state.authentication = payload
    },
    setAccount(state, payload) {
      state.account = payload
    },
    setUser(state, user) {
      state.user = user;
    },
  },
  actions: {
    async addAuthentication({
      commit
    }, authenticationParam) {

      const authentication = await AuthenticationService.addEntity(authenticationParam)

      commit('setAuthentication', authentication)

      const account = await AccountService.getEntity({
        id: authentication.account.id
      })
      // 设置account
      commit('setAccount', account)
      // 设置user
      commit('setUser', authentication.user)

      return authentication
    },
    // 前端 登出
    deleteAuthentication({
      commit
    }) {
      commit('setAuthentication', {})
      commit('setAccount', {})
      commit('setUser', {})
    },
    async getAccount({ state, commit }) {
      const account = await AccountService.getEntity({
        id: state.account.id
      })
      // 设置account
      commit('setAccount', account)
    }
  },
  modules: {
  },
  plugins: process.env.NODE_ENV !== 'production'
    ? [createPersistedState({ storage: window.sessionStorage })]
    : [
      createLogger(),
      createPersistedState({ storage: window.sessionStorage }),
    ],
})
