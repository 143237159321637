/**
 *
 */
const Platform = Object.freeze({
    PC: {
        key:"PC",
        description: "电脑端"
    },
    MOBILE: {
        key:"MOBILE",
        description: "移动端"
    }
})

export default Platform