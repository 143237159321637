<template>
  <div>
    <a-button
      type="primary"
      @click="handleClick"
      :icon="icon"
      :loading="loading"
    >
      <slot></slot>
    </a-button>
  </div>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    ghost: {
      type: Boolean,
      default: false,
    },
    htmlType: {
      type: String,
      default: "",
    },
    icon: {
      type: String,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    shape: {
      type: String,
    },
    size: {
      type: String,
      default: "",
    },
    buttonType: {
      type: String,
      default: "",
    },
    block: {
      type: Boolean,
      default: false,
    },
    service: {
      type: Object,
    },
    sorter: {
      type: Array,
      default: () => {
        return [
          {
            field: "createTime",
            order: "DESC",
          },
        ];
      },
    },
  },
  data() {
    return {};
  },
  methods: {
    async handleClick(event) {
      event.stopPropagation();

      const sortable = {
        sort: [],
      };

      if (this.sorter.length > 0) {
        for (const sort of this.sorter) {
          sortable.sort.push(
            `${sort.field},${sort.order ? sort.order : "DESC"}`
          );
        }
      }

      const response = await this.service.getEntityFile({}, sortable);

      const blob = new Blob([response]);

      const url = window.URL.createObjectURL(blob);

      let link = document.createElement("a");
      link.style.display = "none";
      link.href = url;
      // 生成时间戳
      link.download = new Date().getTime() + ".xls";
      document.body.appendChild(link);
      link.click();

      this.$emit("click", event.target);
    },
  },
};
</script>

<style></style>
