/**
 * 询盘订单类型
 */
 const EnquiryType = Object.freeze({
    SUPPLIER: {
        key:"SUPPLIER",
        description: "供应"
    },
    NEEDS
    : {
        key:"NEEDS",
        description: "需求"
    },
})

export default EnquiryType