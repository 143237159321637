"use strict";

import Vue from "vue";
import axios from "axios";
import qs from "qs";

import store from "../store";
// import router from "../router";

// Full config:  https://github.com/axios/axios#request-config
// axios.defaults.baseURL = process.env.baseURL || process.env.apiUrl || '';
// axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

let config = {
  baseURL: process.env.VUE_APP_API_BASE_URL || "",
  // timeout: 60 * 1000, // Timeout
  // withCredentials: true, // Check cross-site Access-Control
  paramsSerializer: function (params) {
    return qs.stringify(params, { arrayFormat: "indices", allowDots: true });
  },
};

const _axios = axios.create(config);

_axios.interceptors.request.use(
  function (config) {
    // Do something before request is sent

    if (store.state.authentication && store.state.authentication.token) {
      config.headers = {
        ...config.headers,
        Authorization: `Bearer ${store.state.authentication.token}`,
      };
    }

    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
_axios.interceptors.response.use(
  function (response) {
    // Do something with response data
    if (response.headers["content-type"] == "application/x-download") {
      return response;
    }

    return response.data;
  },
  // async function(error) {
  //   // Do something with response error
  //   if (error.response.status && error.response.status === 401) {
  //     await store.dispatch("deleteAuthentication");
  //
  //     router.push({ path: "/authentication" });
  //   }
  //
  //   return Promise.reject(error);
  // }
);

Vue.axios = _axios;
window.axios = _axios;

export default _axios;
