/**
 * 
 */
 const FolderType = Object.freeze({
    VIDEO: {
        key:"VIDEO",
        description: "视频集"
    },
    PICTURE: {
        key:"PICTURE",
        description: "图片集"
    },
    FILE: {
        key:"FILE",
        description: "文件夹"
    }
})

export default FolderType