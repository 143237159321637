<template>
  <a-range-picker
    v-if="type === 'range'"
    v-model="actualValue"
    :placeholder="placeholder"
    :show-time="showTime"
    @ok="handleChange"
  >
  </a-range-picker>

  <a-date-picker
    v-else
    v-model="actualValue"
    :placeholder="placeholder"
    :show-time="showTime"
    @ok="handleChange"
  />
</template>

<script>
import moment from "moment";

export default {
  model: {
    prop: "value",
    event: "change",
  },
  props: {
    type: {
      type: String,
    },
    value: Number,
    startDateTime: Number,
    endDateTime: Number,
    allowClear: Boolean,
    disabled: Boolean,
    placeholder: [String, Array],
    inputReadOnly: {
      type: Boolean,
      default: true,
    },
    defaultValue: [Number, Array],
    showTime: [Boolean, Object],
  },
  computed: {
    actualValue: {
      get: function () {
        switch (this.type) {
          case "range":
            console.log(this.value);

            return Array.isArray(this.value)
              ? this.value.map((v) => {
                  return v ? moment(v) : moment();
                })
              : [moment(this.startDateTime), moment(this.endDateTime)];
          default:
            if (this.value) {
              return moment(this.value);
            } else {
              const current = moment();
              this.$emit("change", current.valueOf());
              return current;
            }
        }
      },
      set: function (value) {
        switch (this.type) {
          case "range":
            if (Array.isArray(value)) {
              if (value.length > 0) {
                this.$emit("update:startDateTime", value[0].valueOf());

                if (value.length > 1) {
                  this.$emit("update:endDateTime", value[1].valueOf());
                }

                this.$emit(
                  "change",
                  value.map((v) => v.valueOf())
                );
              }
            }
            break;
          default:
            this.$emit("change", value ? value.valueOf() : moment().valueOf());
            break;
        }
      },
    },
  },
  methods: {
    handleChange(value) {
      switch (this.type) {
        case "range":
          if (Array.isArray(value)) {
            if (value.length > 0) {
              this.$emit("update:startDateTime", value[0].valueOf());

              if (value.length > 1) {
                this.$emit("update:endDateTime", value[1].valueOf());
              }

              this.$emit(
                "change",
                value.map((v) => v.valueOf())
              );
            }
          }
          break;
        default:
          console.log(value);

          this.$emit("change", value.valueOf());
          break;
      }
    },
  },
};
</script>

<style>
</style>