import request from "../libs/request";
import EntityService from "./entity";

class AccountService extends EntityService {
  constructor(name) {
    super(name)
  }
  async updatePassword(password, account) {
    const result = await request({
      url: `/account/${account.id}/password`,
      method: 'put',
      data: password
    })
    return result
  }
}
export default new AccountService("account")
