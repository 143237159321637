<template>
  <a-tree-select
    class="tree-select"
    v-model="current"
    :allowClear="allowClear"
    :defaultValue="defaultValue"
    :disabled="disabled"
    :multiple="multiple"
    :placeholder="placeholder"
    :treeCheckable="treeCheckable"
    :treeCheckStrictly="false"
    :treeData="dataSource"
    :replaceFields="replaceFields"
    :treeDefaultExpandAll="treeDefaultExpandAll"
    @change="handleChange"
  >
  </a-tree-select>
</template>

<script>
import { TreeSelect } from "ant-design-vue";
const SHOW_PARENT = TreeSelect.SHOW_PARENT;

export default {
  model: {
    prop: "value",
    event: "change",
  },
  props: {
    whole: {
      type: Boolean,
      default: true,
    },
    dataSource: Array,
    value: [String, Object, Array],
    allowClear: {
      type: Boolean,
      default: true,
    },
    defaultValue: Object,
    disabled: Boolean,
    placeholder: String,
    replaceFields: {
      type: Object,
      default: function () {
        return {
          children: "subordinates",
          title: "name",
          key: "id",
          value: "id",
        };
      },
    },
    treeCheckable: {
      type: Boolean,
      default: false,
    },
    treeCheckStrictly: {
      type: Boolean,
      default: false,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    treeDefaultExpandAll: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      SHOW_PARENT,
      current: "",
    };
  },

  watch: {
    value: {
      handler(val) {
        console.log("watch:value");
        console.log(val);

        let current = null;

        if (this.treeCheckable && this.treeCheckStrictly) {
          current = val.map((v) => {
            const d = this.deepQuery(this.dataSource, v.id);
            return d ? { label: d.name, value: d.id } : {};
          });
        } else {
          //判断是否为数组
          if (this.treeCheckable || this.multiple) {
            current = val.map((v) => {
              const d = this.deepQuery(this.dataSource, v.id);
              return d ? d.name : "";
            });
          } else {
            if(val){
              const d = this.deepQuery(this.dataSource, val.id);

              current = d ? d.name : "";
            }else{
              current = ""
            }

          }
        }

        console.log(current);

        this.current = current;
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    handleChange(value, label, extra) {
      console.log(value, label, extra);
      let result;

      if (value != undefined) {
        if (this.treeCheckable && this.treeCheckStrictly) {
          //Checkable只有多选
          //是否返回完整对象
          if (this.whole) {
            result = value
              .filter((v) => {
                return !!v;
              })
              .map((v) => {
                console.log(v);
                return this.deepQuery(this.dataSource, v.value);
              });
          } else {
            result = value
              .filter((v) => {
                return !!v;
              })
              .map((v) => {
                return { id: v.value };
              });
          }
        } else {
          //判断是否为多选
          if (this.treeCheckable || this.multiple) {
            //是否返回完整对象
            if (this.whole) {
              result = value
                .filter((v) => {
                  return !!v;
                })
                .map((i) => this.deepQuery(this.dataSource, i));
            } else {
              result = value.map((i) => {
                return { id: i };
              });
            }
          } else {
            //是否返回完整对象
            if (this.whole) {
              //返回完整对象
              result = this.deepQuery(this.dataSource, value);
            } else {
              //返回简单对象
              result = { id: value };
            }
          }
        }
      } else {
        //非枚举类型
        result = {};
      }

      console.log(result);

      this.current = result;
      this.$emit("change", result);
    },
    deepQuery(tree, id) {
      var isGet = false;
      var retNode = null;
      function deepSearch(tree, id) {
        for (var i = 0; i < tree.length; i++) {
          if (tree[i].subordinates && tree[i].subordinates.length > 0) {
            deepSearch(tree[i].subordinates, id);
          }
          if (id === tree[i].id || isGet) {
            isGet || (retNode = tree[i]);
            isGet = true;
            break;
          }
        }
      }
      deepSearch(tree, id);
      return retNode;
    },
    breadthQuery(tree, id) {
      var stark = [];
      stark = stark.concat(tree);
      while (stark.length) {
        var temp = stark.shift();
        if (temp.subordinates) {
          stark = stark.concat(temp.subordinates);
        }
        if (temp.id === id) {
          return temp;
        }
      }
    },
  },
};
</script>

<style lang="less" scoped>
.tree-select {
  width: 100%;
}
</style>
