/**
 *消息的状态
 */
 const MessageStatus = Object.freeze({
    SENT: {
        key:"SENT",
        description: "已发送"
    },
    READ: {
        keyL:"READ",
        description: "已读"
    }
})

export default MessageStatus