<template>
  <div>
    <a-upload
      :accept="accept"
      :action="action"
      :disabled="disabled"
      :list-type="listType"
      :file-list="fileList"
      :headers="headers"
      :showUploadList="showUploadList"
      @change="handleChange"
      @preview="handlePreview"
    >
      <div
        v-if="listType == 'picture-card' && fileList.length < maximumQuantity"
      >
        <a-icon type="plus" />
        <div class="ant-upload-text">上传</div>
      </div>
      <a-button
        v-if="listType == 'text' && fileList.length < maximumQuantity"
        icon="upload"
        >上传</a-button
      >
    </a-upload>
    <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel">
      <img alt="example" style="width: 100%" :src="previewImage" />
    </a-modal>
  </div>
</template>

<script>
import { mapState } from "vuex";

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

export default {
  model: {
    prop: "value",
    event: "change",
  },
  props: {
    type: {
      type: String,
      default: "text",
    },
    value: [String, Array],
    fileRecord: {
      type: Object,
    },
    maximumQuantity: {
      type: Number,
      default: 1,
    },
    minimumQuantity: {
      type: Number,
      default: 1,
    },
    accept: {
      type: String,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    showUploadList: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    ...mapState({
      authentication: (state) => state.authentication,
    }),
    action: function () {
      return process.env.VUE_APP_API_BASE_URL + "/file";
    },
    headers: function () {
      const headers = {};

      if (this.authentication && this.authentication.token) {
        headers.Authorization = `Bearer ${this.authentication.token}`;
      }

      return headers;
    },
    listType: {
      get: function () {
        switch (this.type) {
          case "picture":
            return "picture-card";
          case "text":
          default:
            return "text";
        }
      },
      set: function () {},
    },
  },
  data() {
    return {
      files: [],
      fileList: [],
      previewVisible: false,
      previewImage: "",
    };
  },
  watch: {
    value: {
      handler: function (value) {
        this.initValue(value);
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    beforeUpload() {
      return true;
    },
    handleChange({ fileList }) {
      this.fileList = fileList;

      if (
        fileList.filter((f) => {
          return f.status == "done";
        }).length == fileList.length
      ) {
        console.log(fileList);

        //全部都上传完成
        if (this.fileList.length > 0) {
          if (this.maximumQuantity === 1) {
            console.log(this.fileList[0].response.url);

            this.$emit("change", this.fileList[0].response.url);
            this.$emit(
              "update:fileRecord",
              this.fileList[0].response.fileRecord
            );
          } else {
            this.$emit(
              "change",
              this.fileList.map((file) => file.url || file.response.url)
            );
            // TODO 此处应支持多个FileRecord返回
          }
        } else {
          if (this.maximumQuantity > 1) {
            this.$emit("change", []);
          } else {
            this.$emit("change", "");
          }
        }
      }
    },
    async handlePreview(file) {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj);
      }
      this.previewImage = file.url || file.preview;
      this.previewVisible = true;
    },
    handleCancel() {
      this.previewVisible = false;
    },
    initValue(value) {
      //传入值为数组
      if (Array.isArray(value)) {
        //且数量与文件数量不一致时
        if (value.length != this.fileList.length) {
          // 更新文件列表
          this.fileList = value.map((file, index) => {
            return {
              uid: index,
              name: file
                ? file.split("/").pop().split("#").shift().split("?").shift()
                : null,
              status: "done",
              url: file,
            };
          });
        }
      } else {
        //传入有效文本
        if (value) {
          // 传入值为文本，即仅有1个文件地址
          // 列表数量不为1时需要更新列表
          // 文件列表唯一一个地址不同则更新列表
          if (
            this.fileList.length != 1 ||
            this.fileList[0].response.url != value
          ) {
            this.fileList = [
              {
                uid: "0",
                name: value
                  ? value.split("/").pop().split("#").shift().split("?").shift()
                  : null,
                status: "done",
                url: value,
                response: {
                  url: value,
                },
              },
            ];
          }
        } else {
          //传入无效数据
          this.fileList = [];
        }
      }
    },
  },
  mounted() {
    switch (this.type) {
      case "picture":
        this.listType = "picture-card";
        break;
      case "text":
      default:
        this.listType = "text";
    }

    this.initValue(this.value);
  },
};
</script>

<style>
.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}
</style>
