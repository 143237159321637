import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from './plugins/i18n'

import './plugins/axios'
import './plugins/ant-design-vue.js'
import "./plugins/vue-fullscreen";
import "./plugins/yiyao";

import "./libs/array";
import "./libs/date";

Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
