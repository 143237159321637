Array.prototype.toTree = function (a = "superior", b = "subordinates") {

    var map = {}, tree = [];

    for (const item of this) {
        map[item.id] = item;
    }

    for (const node of this) {

        if (node[a] && node[a].id) {

            if (map[node[a].id] !== undefined) {

                if (!map[node[a].id][b]) {
                    map[node[a].id][b] = []
                }

                map[node[a].id][b].push(node);
            }
        } else {

            tree.push(node);
        }
    }

    return tree;
}
