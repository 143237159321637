/**
 * 
 */
const FileRecordType = Object.freeze({
    VIDEO: {
        key:"VIDEO",
        description: "视频"
    },
    PICTURE: {
        key:"PICTURE",
        description: "图片"
    },
    FILE: {
        key:"FILE",
        description: "文件类型"
    },
})

export default FileRecordType