/**
 * 文档的类型
 */
const DocumentFormat = Object.freeze({
    MARKDOWN: {
        key:"MARKDOWN",
        description: "MARKDOWN文档"
    },
    HTML: {
        key:"HTML",
        description: "富文本"
    },
    PDF: {
        key:"PDF",
        description: "PDF文件"
    },
    WORD: {
        key:"WORD",
        description: "文档"
    }
})

export default DocumentFormat