/**
 * 
 */
 const Progress = Object.freeze({
    SENDING: {
        key:"SENDING",
        description: "发送中"
    },
    NEEDS
    : {
        key:"SENDED",
        description: "已发送"
    },
})

export default Progress